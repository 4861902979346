<template>
  <ValidationObserver v-slot="{ invalid }">
    <EuiDialog @onClose="$emit('closeDialog')" :open="true" size="medium">
        <template #header>{{ $t('paraphers.dialogs.createSignatory.title') }}</template>
        <form @submit.prevent="createSignatory"
              id="createSignatoryForm"
              class="eui-u-flex eui-u-flex-col">
          <ValidationProvider v-if="can('signerCreationFormDefineEntityType')"
                              slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.form.entity')"
                              rules="required"
                              v-slot="{ errors, valid, invalid, touched }">
            <EuiTextField :valid="valid && touched"
                          :invalid="errors.length > 0"
                          :warning="errors.length === 0"
                          :errorMessage="errors[0]">
              <EuiLabel>{{ $t('signatory.form.entity') }}*</EuiLabel>
              <EuiSelect v-model="newSignatory.entityType">
                <option value="person" key="person">{{ $t('signatory.form.entity.person') }}</option>
                <option value="society" key="society">{{ $t('signatory.form.entity.society') }}</option>
                <option value="organism" key="organism">{{ $t('signatory.form.entity.public') }}</option>
              </EuiSelect>
            </EuiTextField>
          </ValidationProvider>
          <ValidationProvider slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.table.firstname')"
                              rules="required|title"
                              v-slot="{ errors, valid, invalid, touched }">
            <EuiTextField :label="$t('signatory.table.firstname')"
                          type="text"
                          adaptSize
                          v-model="newSignatory.firstname"
                          :valid="valid && touched"
                          :warning="invalid && errors.length === 0"
                          :invalid="errors.length > 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.table.name')"
                              rules="required|title"
                              v-slot="{ errors, valid, invalid, touched }">
            <EuiTextField :label="$t('signatory.table.name')"
                          type="text"
                          adaptSize
                          v-model="newSignatory.lastname"
                          :valid="valid && touched"
                          :invalid="errors.length > 0"
                          :warning="invalid && errors.length === 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.table.email')"
                              rules="required|email"
                              v-slot="{ errors, valid, invalid, touched }">
            <EuiTextField :label="$t('signatory.table.email')"
                          type="email"
                          adaptSize
                          v-model.trim="newSignatory.email"
                          :valid="valid && touched"
                          :invalid="email.isInUse || errors.length > 0"
                          :warning="invalid && errors.length === 0"
                          :errorMessage="email.isInUse ? email.message : errors[0] "/>
          </ValidationProvider>
          <ValidationProvider v-if="can('signerCreationFormDefineMobilePhone')"
                              slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.form.phone')"
                              rules="phone"
                              mode="lazy"
                              v-slot="{ errors, valid, touched }">
            <EuiTextField :label="$t('signatory.form.phone')"
                          type="text"
                          adaptSize
                          v-model="newSignatory.mobilePhone"
                          :valid="valid && touched && newSignatory.mobilePhone > 0"
                          :invalid="errors.length > 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider v-if="can('signerCreationFormDefineAddress')"
                              slim
                              class="eui-u-mb-2"
                              mode="eager"
                              :name="$t('signatory.form.address')"
                              rules="min:3"
                              v-slot="{ errors, valid, touched }">
            <EuiTextField type="text"
                          adaptSize
                          :valid="valid && touched"
                          :invalid="errors.length > 0"
                          :errorMessage="errors[0]">
              <EuiLabel>{{ $t('signatory.form.address') }}</EuiLabel>
              <EuiTextarea fullWidth
                           noResize
                           v-model="newSignatory.address"
                           :valid="valid && touched"
                           :invalid="errors.length > 0"/>
            </EuiTextField>
          </ValidationProvider>
          <ValidationProvider v-if="can('signerCreationFormDefineAddress')"
                              slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.form.zip')"
                              rules="postal_code"
                              mode="eager"
                              v-slot="{ errors, valid, touched }">
            <EuiTextField :label="$t('signatory.form.zip')"
                          type="text"
                          adaptSize
                          v-model.trim="newSignatory.postalCode"
                          :valid="valid && touched && newSignatory.postalCode.length > 0"
                          :invalid="errors.length > 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider v-if="can('signerCreationFormDefineAddress')"
                              slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.form.town')"
                              rules="min:3"
                              mode="eager"
                              v-slot="{ errors, valid, touched }">
            <EuiTextField :label="$t('signatory.form.town')"
                          type="text"
                          adaptSize
                          v-model="newSignatory.town"
                          :valid="valid && touched && newSignatory.town.length > 2"
                          :invalid="errors.length > 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
          <!-- logo -->
          <div class="eui-u-mb-12 eui-u-flex eui-u-flex-col">
            <EuiHeading :level="4" bold class="eui-u-mb-4">{{ $t('signatory.form.chooseLogo') }}</EuiHeading>
              <div class="eui-u-mt-1">
                <EuiButton type="button" @click="$refs.logoUploader.click()" variant="raised" size="small" color="primary">
                  {{ $t('button.select') }}
                </EuiButton>
              </div>
          </div>
          <div v-if="newSignatory.logo">
            <div class="es-signatory__logo">
              <img :src="'data:image/png;base64,' + newSignatory.logo"/>
              <div class="es-signatory__logo__hover-panel">
                <EuiIcon name="more_vert"/>
                <EuiButton size="large" variant="text" @click="newSignatory.logo = undefined"  iconOnly color="primary">
                  <EuiIcon outlined name="delete"/>
                </EuiButton>
              </div>
            </div>
          </div>
          <ValidationProvider v-if="can('signerCreationFormDefineService')"
                              slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.form.jobSector')"
                              rules="min:3"
                              mode="eager"
                              v-slot="{ errors, valid, touched }">
            <EuiTextField :label="$t('signatory.form.jobSector')"
                          type="text"
                          adaptSize
                          v-model="newSignatory.service"
                          :valid="valid && touched && newSignatory.service.length > 0"
                          :invalid="errors.length > 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider v-if="can('signerCreationFormDefineFunction')"
                              slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.form.jobRole')"
                              rules="min:3"
                              mode="eager"
                              v-slot="{ errors, valid, touched }">
            <EuiTextField :label="$t('signatory.form.jobRole')"
                          type="text"
                          adaptSize
                          v-model="newSignatory.function"
                          :valid="valid && touched && newSignatory.function.length > 0"
                          :invalid="errors.length > 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider v-if="can('signerCreationFormDefineReference')"
                              slim
                              class="eui-u-mb-2"
                              :name="$t('signatory.form.reference')"
                              rules="min:3"
                              mode="eager"
                              v-slot="{ errors, valid, touched }">
            <EuiTextField :label="$t('signatory.form.reference')"
                          type="text"
                          adaptSize
                          v-model="newSignatory.reference"
                          :valid="valid && touched && newSignatory.reference.length > 0"
                          :invalid="errors.length > 0"
                          :errorMessage="errors[0]"/>
          </ValidationProvider>
          <input ref="logoUploader"
                 style="display: none"
                 type="file"
                 accept="image/png, image/jpeg"
                 @click="resetLogoUploader"
                 @change="uploadFile($event.target.files[0])">
        </form>
        <!-- confirmation button -->
        <template #footer>
          <EuiButton variant="text"
                     color="primary"
                     @click="$emit('closeDialog')">
            {{ $t('button.cancel') }}
          </EuiButton>
          <EuiButton :disabled="invalid"
                     variant="raised"
                     color="primary"
                     type="submit"
                     form="createSignatoryForm">
            {{ $t('button.create') }}
          </EuiButton>
        </template>
    </EuiDialog>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CreateSignatoryDialog',
  props: {
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      newSignatory: {
        entityType: 'person',
        firstname: '',
        lastname: '',
        email: '',
        mobilePhone: '',
        address: '',
        postalCode: '',
        town: '',
        logo: undefined,
        function: '',
        service: '',
        reference: '',
      },
      email: {
        isInUse: false,
        message: this.$i18n.t('signatory.form.email.error.emailAlreadyUsed'),
      },
    };
  },
  computed: {
    ...mapGetters({
      options: 'ModuleEdocSign/auth/options',
      can: 'ModuleEdocSign/auth/can',
    }),
  },
  methods: {
    ...mapActions({
      storeCreateSignatory: 'ModuleEdocSign/signatory/createSignatory',
    }),
    resetLogoUploader() {
      this.$refs.logoUploader.value = '';
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.result);
      });
    },
    uploadFile(file) {
      this.getBase64(file).then((res) => {
        this.newSignatory.logo = res.split(';')[1].split(',')[1];
      });
    },
    // check in profile options if the input form field is available
    formFieldIsAvailable(value) {
      if (this.options && this.options[value]) {
        return parseInt(this.options[value].value);
      }
      return value === undefined;
    },
    createSignatory() {
      this.storeCreateSignatory(this.newSignatory)
        .then((response) => {
          this.$emit('createdSignatory', response.data);
          this.$emit('closeDialog');
        })
        .catch((error) => {
          if (error.data.message === 'email already in use in domain') {
            this.email.isInUse = true;
          }
        });
    },
  },
};
</script>
