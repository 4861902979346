<template>
  <EuiGridRow class="eds-addSignatories">
    <AddSignatoriesDialog
      :selected.sync="selected"
      :selectedSignatoryIndex="selectedSignatoryIndex"
      :selectedOrganizations="Signbook.recipientOrganizations.map(value => value.organizationId)"
      :selectedUserType="selectedUserType"
      :singleTypeMode="isTemplate"
      :options="options"
      @change="setSign"
      @closeDialog="closeDialog"
      :open.sync="addSignDialog"/>
    <CreateSignatoryDialog
      @createdSignatory="createdSignatory"
      @closeDialog="createSignDialog = false"
      v-if="createSignDialog"/>
    <EuiGridColumn width="2/3">
      <EuiBlock :title="$t('paraphers.create.addSignatories.title')">
        <template v-if="routeName.startsWith('paraphers.create')">
          <EuiHeading :level="4" bold class="eui-u-mt-3 eui-u-text-blue-grey">
            {{ $t('paraphers.create.addSignatories.description') }}
          </EuiHeading>
          <div class="mt-4">
            <EuiButton class="mr-5" variant="raised" size="small" color="primary" @click="addSignatory()" name="ajoutSignataire">
              {{ $t('button.select') }}
            </EuiButton>
            <EuiButton v-if="can('parapherCreationFormSignerCreationAvailable', true)" variant="raised" size="small" color="primary" @click="createSignatory()">
              {{ isDeskRhDomain ? $t('paraphers.create.addSignatories.createEmployee'): $t('button.create') }}
            </EuiButton>
          </div>
        </template>
        <EuiHeading :level="4" bold class="eui-u-mt-8 eui-u-mb-2" v-if="Signbook.signatories.length > 0">
          {{ $t('paraphers.create.addSignatories.signatoriesOrder')}}
        </EuiHeading>
        <ValidationProvider ref="signatories" :name="$t('template.details.form.steps.signatoryField')"
                            rules="socialSign|templateSignatories"
                            v-slot="{ errors }">
          <div v-if="routeName.startsWith('paraphers.create')">
            <p v-for="(error, index) in errors" :key="index" class="eui-u-my-4 eui-u-text-base eui-u-font-bold eui-u-text-red">
              {{ error }}
            </p>
          </div>
          <draggable tag="div"
                     class="container"
                     v-model="Signbook.signatories"
                     handle=".handle"
                     @change="onChange"
                     :disabled="!routeName.startsWith('paraphers.create')">
            <transition-group tag="div" type="transition" name="flip-list">
              <EuiExpansionPanel v-for='(element, index) in Signbook.signatories'
                                 :key='`sign_${index}`'
                                 class="bg-white"
                                 :class="{'bt-px': index === 0 }">
                <template v-slot:header>
                  <div class="col1 eui-u-w-full eui-u-flex eui-u-justify-center eui-u-items-center eui-u-bg-primary eui-u-text-white eui-u-h-full" @click.stop>
                    <template v-if="Signbook.signatureOrdering === 'sequentially'
                                || (Signbook.signatureOrdering === 'hybrid' && index === 0)">
                      {{ index + 1 }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <EuiIcon v-if="routeName.startsWith('paraphers.create')" name="nouveaudrag" color="primary" class="handle cursor-move"/>
                  <div v-else></div>
                  <div>
                    <div class="eui-u-text-blue-grey eui-u-text-xs eui-u-font-bold eui-u-mb-2 eui-u-leading-none">{{ getSignatoryType(element) }}</div>
                    <div class="eui-u-text-xs">
                      <template v-if="element.fullname">
                        {{element.fullname}}
                      </template>
                      <template v-else-if="errors.length > 0">
                        <span class="eui-u-font-italic eui-u-text-red">
                          {{ errors[0] }}
                        </span>
                      </template>
                      <template v-else>
                        <span :class="['eui-u-font-italic']">
                          {{ $t('paraphers.create.addSignatories.selectSignatory') }}
                        </span>
                      </template>
                    </div>
                  </div>
                  <div>
                    <template v-if="element.type === 'externalSignatory'">
                      <div class="eui-u-text-blue-grey eui-u-text-xs eui-u-font-bold eui-u-mb-2 eui-u-leading-none">{{ $t('paraphers.create.addSignatories.organization') }}</div>
                      <div class="eui-u-text-xs">
                        <template v-if="element.organizations && element.organizations.length > 0">
                          {{ element.organizations.map(value => value.name).join(', ') }}
                        </template>
                        <template v-else>
                          --
                        </template>
                      </div>
                    </template>
                  </div>
                  <div @click.stop>
                    <EuiTextField>
                      <EuiLabel>{{ $t('paraphers.create.addSignatories.date') }}</EuiLabel>
                      <EuiInput type="date" :min="minDate(index)" :max="maxDate(index)" v-model="element.date"/>
                    </EuiTextField>
                  </div>
                  <div>
                    <template v-if="element.eDocPersoCreated">
                      <div class="eui-u-text-blue-grey eui-u-text-xs eui-u-font-bold eui-u-mb-2 eui-u-leading-none">
                        {{ $t('paraphers.create.addSignatories.eDocPerso')}}
                      </div>
                      <EuiCheckbox v-model="element.archiveToEdocPerso">
                        {{ $t('paraphers.create.addSignatories.archive') }}
                      </EuiCheckbox>
                    </template>
                  </div>
                  <div v-if="!hasProperSignBefore(element, index)">
                    <EuiTooltip :content="$t('paraphers.create.addSignatories.error.socialSigner')" placement="top">
                      <template slot="anchor">
                        <EuiIcon class="eui-u-mr-4" name="mark" color="warning" outlined/>
                      </template>
                    </EuiTooltip>
                  </div>
                  <div>
                    <EuiDropdownMenu v-if="routeName.startsWith('paraphers.create')" class="-noToggle last-col">
                      <EuiList>
                        <EuiListItem :button="true"
                                     @click="selectedSignatoryIndex = index; selectedUserType = element.type; addSignDialog = true">
                          {{ $t('button.change') }}
                        </EuiListItem>
                        <EuiListItem :button="true"
                                     @click="removeSignatory(element)">
                          {{ $t('button.delete') }}
                        </EuiListItem>
                      </EuiList>
                      <template slot="anchor">
                        <EuiButton iconOnly color="primary">
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                    </EuiDropdownMenu>
                    <div v-else class="eui-u-flex" @click.stop>
                      <EuiDropdownMenu class="-noToggle last-col">
                        <EuiList>
                          <EuiListItem :button="true"
                                       @click="createSignatory(index)">
                            {{ isDeskRhDomain ? $t('paraphers.create.addSignatories.createEmployee'): $t('button.create') }}
                          </EuiListItem>
                          <EuiListItem :button="true"
                                       @click="selectedSignatoryIndex = index; selectedUserType = element.type; addSignDialog = true">
                            {{ $t('button.upload') }}
                          </EuiListItem>
                        </EuiList>
                        <template slot="anchor">
                          <EuiButton iconOnly color="primary">
                            <EuiIcon name="more_vert"/>
                          </EuiButton>
                        </template>
                      </EuiDropdownMenu>
                    </div>
                  </div>
                </template>
                <div class="eui-u-flex eui-u-flex-col eui-u-flex-grow eui-u-ml-8 eui-u-my-4">
                  <EuiHeading :level="4" bold class="eui-u-mt-0 eui-u-mb-3">{{ $t('common.advancedParameters') }} :</EuiHeading>
                  <EuiTextField adaptSize class="mt-2" noResize>
                    <EuiLabel>{{ $t('signworkflows.create.step1.signatories.comment.label') }}</EuiLabel>
                    <EuiTextarea fullWidth
                                 :rows="3"
                                 v-model.trim="element.comment"
                                 autocomplete="personnal-comment"
                                 :valid="element.comment !== null && element.comment !== undefined"/>
                  </EuiTextField>
                </div>
              </EuiExpansionPanel>
            </transition-group>
          </draggable>
        </ValidationProvider>
      </EuiBlock>
    </EuiGridColumn>
    <EuiGridColumn width="1/3">
      <EuiBlock :title="$t('paraphers.create.addSignatories.parameters')">
        <div class="eui-u-flex eui-u-flex-wrap eui-u-flex-col">
          <div>
            <EuiHeading :level="4" bold class="eui-u-mb-3 eui-u-mt-0">
              {{ $t('paraphers.create.addSignatories.signatureOrdering.title') }}
            </EuiHeading>
            <div  class="eui-u-flex eui-u-flex-col">
              <div>
                <EuiRadio v-model="Signbook.signatureOrdering" name="signatureOrdering" value="sequentially" :disabled="isTemplate">
                  {{ $t('paraphers.create.addSignatories.signatureOrdering.sequentially') }}
                </EuiRadio>
                <EuiTooltip :content="$t('paraphers.create.addSignatories.signatureOrdering.sequentiallyTooltip')" placement="top">
                  <template slot="anchor">
                    <EuiIcon class="eui-u-ml-1 eui-u-mr-5" name="infosperso" color="primary"/>
                  </template>
                </EuiTooltip>
              </div>
              <div>
                <EuiRadio v-model="Signbook.signatureOrdering" name="signatureOrdering" value="simultaneously" :disabled="isTemplate">
                  {{ $t('paraphers.create.addSignatories.signatureOrdering.simultaneously') }}
                </EuiRadio>
                <EuiTooltip :content="$t('paraphers.create.addSignatories.signatureOrdering.simultaneouslyTooltip')" placement="top">
                  <template slot="anchor">
                    <EuiIcon class="eui-u-ml-1 eui-u-mr-5" name="infosperso" color="primary"/>
                  </template>
                </EuiTooltip>
              </div>
              <div>
                <EuiRadio v-model="Signbook.signatureOrdering" name="signatureOrdering" value="hybrid" :disabled="isTemplate">
                  {{ $t('paraphers.create.addSignatories.signatureOrdering.hybrid') }}
                </EuiRadio>
                <EuiTooltip :content="$t('paraphers.create.addSignatories.signatureOrdering.hybridTooltip')" placement="top">
                  <template slot="anchor">
                    <EuiIcon class="eui-u-ml-1" name="infosperso" color="primary"/>
                  </template>
                </EuiTooltip>
              </div>
            </div>
          </div>
          <div>
            <EuiHeading :level="4" bold class="eui-u-mb-3">
              {{ $t('paraphers.create.addSignatories.allowDecline.title') }}
            </EuiHeading>
            <EuiCheckbox v-model="Signbook.allowDecline" :disabled="isTemplate">
              {{ $t('paraphers.create.addSignatories.allowDecline.label') }}
            </EuiCheckbox>
          </div>
        </div>
      </EuiBlock>
    </EuiGridColumn>
  </EuiGridRow>
</template>

<script>
import draggable from 'vuedraggable';
import {mapActions, mapGetters, mapState} from 'vuex';
import initials from '../../../utils/initials';
import AddSignatoriesDialog from '../dialogs/AddSignatoriesDialog';
import CreateSignatoryDialog from '../../../pages/paraphers/dialogs/CreateSignatoryDialog';

export default {
  name: 'CreateParapherAddSignatories',
  inject: ['Signbook', 'Validation'],
  components: {
    AddSignatoriesDialog,
    CreateSignatoryDialog,
    draggable,
  },

  watch: {
    signatoryFromDeskRhEmpCreation() {
      const organizations = this.Signbook.recipientOrganizations.map((orga) => {
        return {
          name: orga.issuerName,
          id: orga.organizationId,
          siret: orga.siret,
        };
      });
      if (this.routeName === 'paraphers.create.addSignatories') {
        const newSignatory = {...this.signatoryFromDeskRhEmpCreation};
        newSignatory.organizations = organizations;
        newSignatory.comment = null;
        this.Signbook.signatories.push(newSignatory);
        this.selected = this.Signbook.signatories.map(({ id, date, signerOnlyValidate, comment, initials, ...keepAttrs }) => keepAttrs);
      } else {
        if (this.signatoryFromDeskRhEmpCreation.eDocPersoCreated) {
          this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'eDocPersoCreated', this.signatoryFromDeskRhEmpCreation.eDocPersoCreated);
          this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'archiveToEdocPerso', this.signatoryFromDeskRhEmpCreation.archiveToEdocPerso);
        }
        this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'fullname', this.signatoryFromDeskRhEmpCreation.fullname);
        this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'userId', this.signatoryFromDeskRhEmpCreation.userId);
        this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'organizations', organizations);
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'paraphers.create.addDocuments') {
      if (this.Signbook.signatureOrdering === 'sequentially') {
        this.Signbook.allowSimultaneousSteps = false;
        this.Signbook.allowFirstThenSimultaneousSteps = false;
      } else if (this.Signbook.signatureOrdering === 'simultaneously') {
        this.Signbook.allowSimultaneousSteps = true;
        this.Signbook.allowFirstThenSimultaneousSteps = false;
      } else if (this.Signbook.signatureOrdering === 'hybrid') {
        this.Signbook.allowSimultaneousSteps = false;
        this.Signbook.allowFirstThenSimultaneousSteps = true;
      }
    }
    if (to.meta.step > from.meta.step) {
      if (this.Validation.validateSignatories()) {
        next();
      }
    } else {
      next();
    }
  },
  computed: {
    ...mapState({
      routeName: (state) => state.route.name,
      isDeskRhDomain: (state) => state.ModuleEdocSign.auth.domains.isDeskRh,
    }),
    ...mapGetters({
      options: 'ModuleEdocSign/auth/options',
      can: 'ModuleEdocSign/auth/can',
      signatoryFromDeskRhEmpCreation: 'ModuleEdocSign/parapheurs/signatoryFromDeskRhEmpCreation',
    }),
    isTemplate() {
      return this.routeName.startsWith('templates.use');
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  data() {
    return {
      selected: [],
      selectedUserType: undefined,
      addSignDialog: false,
      createSignDialog: false,
      selectedSignatoryIndex: undefined,
      showError: false,
    };
  },
  methods: {
    ...mapActions({
      storeOpenDeskRhEmpCreation: 'application/openDeskRhEmpCreation',
      pushAlert: 'application/pushAlert',
    }),
    hasProperSignBefore(element, position) {
      if (element.entityType === 'socialSigner') {
        return this.Signbook.signatories.some((item, index) => {
          return element.authorizedUsers.includes(item.userId) && (index < position);
        });
      } else return true;
    },
    isTemplateSignatoriesSelected(signatory) {
      if (this.showError && signatory.email) return true;
      else return false;
    },
    onChange(evt) {
      const newDate = this.Signbook.signatories[evt.moved.newIndex].date;
      const oldDate = this.Signbook.signatories[evt.moved.oldIndex].date;
      this.$set(this.Signbook.signatories[evt.moved.oldIndex], 'date', newDate);
      this.$set(this.Signbook.signatories[evt.moved.newIndex], 'date', oldDate);
    },
    createSignatory(index) {
      this.selectedSignatoryIndex = index;
      if (!this.isDeskRhDomain) {
        this.createSignDialog = true;
      } else if (!this.Signbook.recipientOrganizations.length && this.isDeskRhDomain) {
        this.pushAlert({ message: 'paraphers.create.addSignatories.error.organizationSelectionRequired', fieldOnError: 'signBookRecipientOrganizations' });
      } else {
        this.storeOpenDeskRhEmpCreation({ openDeskRhEmpCreation: true, organization: this.Signbook.recipientOrganizations });
      }
    },
    createdSignatory(value) {
      value.id = this.$uuid.v4();
      value.type = 'externalSignatory';
      value.initials = initials(value.firstname, value.lastname);
      if (this.selectedSignatoryIndex !== undefined) {
        this.Signbook.signsList.forEach(item => {
          if (item.zone.user === this.selectedSignatoryIndex) {
            item.zone.user = value;
          }
        });
        this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'fullname', value.fullname);
        this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'email', value.email);
        this.Signbook.signatories[this.selectedSignatoryIndex].initials = value.initials;
        this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'userId', value.id);
      } else {
        this.Signbook.signatories.push(value);
      }
    },
    closeDialog() {
      this.selectedSignatoryIndex = undefined;
      this.selectedUserType = undefined;
    },
    addSignatory() {
      this.addSignDialog = true;
    },
    removeSignatory(signatory) {
      this.selectedUserType = undefined;
      this.selected = this.selected.filter((element) => element.userId !== signatory.userId);
      this.Signbook.signatories = this.Signbook.signatories.filter((element) => element !== signatory);
      this.Signbook.signsList = this.Signbook.signsList.filter((obj) => obj.zone.user.userId !== signatory.userId);
    },
    setSign(signatories) {
      this.$refs.signatories.validate();
      if (this.selectedUserType !== undefined || this.selectedSignatoryIndex !== undefined) {
        if (Array.isArray(signatories)) {
          if (signatories[0] !== undefined) {
            this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'initials', initials(signatories[0].firstname, signatories[0].lastname));
            this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'fullname', signatories[0].fullname);
            this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'email', signatories[0].email);
            this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'userId', signatories[0].userId);
            this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'type', signatories[0].type);
            this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'date', this.Signbook.endingDate);
            const eDocPersoCreated = this.Signbook.signatories[this.selectedSignatoryIndex].eDocPersoCreated;
            if (eDocPersoCreated &&
              eDocPersoCreated === true &&
              !this.Signbook.signatories[this.selectedSignatoryIndex].archiveToEdocPerso) {
              this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'archiveToEdocPerso', true);
            }
          } else {
            this.closeDialog();
          }
        } else {
          // Update signatory
          this.Signbook.signsList.forEach(item => {
            if (item.zone.user === this.selectedSignatoryIndex) {
              item.zone.user = signatories;
            }
            if (item.zone.user.userId === this.Signbook.signatories[this.selectedSignatoryIndex].userId) {
              item.zone.user = {
                userId: signatories.userId,
                fullname: signatories.fullname,
                email: signatories.email,
                initials: initials(signatories.firstname, signatories.lastname),
              };
            }
          });

          if (this.routeName.startsWith('templates.use')) {
            this.Signbook.signatories[this.selectedSignatoryIndex].signatures.forEach(item => {
              item.zone.user = {
                userId: signatories.userId,
                fullname: signatories.fullname,
                email: signatories.email,
                initials: initials(signatories.firstname, signatories.lastname),
              };
            });
            this.Signbook.signatories[this.selectedSignatoryIndex].paraphs.forEach(item => {
              item.zone.user = {
                userId: signatories.userId,
                fullname: signatories.fullname,
                email: signatories.email,
                initials: initials(signatories.firstname, signatories.lastname),
              };
            });
            this.Signbook.signatories[this.selectedSignatoryIndex].texts.forEach(item => {
              item.zone.user = {
                userId: signatories.userId,
                fullname: signatories.fullname,
                email: signatories.email,
                initials: initials(signatories.firstname, signatories.lastname),
              };
            });
          }

          this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'initials', initials(signatories.firstname, signatories.lastname));
          this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'fullname', signatories.fullname);
          this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'email', signatories.email);
          this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'userId', signatories.userId);
          this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'type', signatories.type);
          const eDocPersoCreated = signatories.eDocPersoCreated;
          if (signatories.organizations.length) {
            this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'organizations', signatories.organizations);
          }
          this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'eDocPersoCreated', eDocPersoCreated);
          if (eDocPersoCreated && eDocPersoCreated === true &&
            !this.Signbook.signatories[this.selectedSignatoryIndex].archiveToEdocPerso) {
            this.$set(this.Signbook.signatories[this.selectedSignatoryIndex], 'archiveToEdocPerso', true);
          }
        }
      } else {
        // Add Signatories
        signatories.forEach((element) => {
          element.id = this.$uuid.v4();
          element.date = this.Signbook.endingDate;
          element.signerOnlyValidate = false;
          const signatory = this.Signbook.signatories.find(e => element.userId === e.userId);
          element.comment = signatory ? signatory.comment : null;
          if (element.eDocPersoCreated === true) {
            element.archiveToEdocPerso = true;
          }
          element.initials = initials(element.firstname, element.lastname);
        });
        this.Signbook.signatories = signatories;
      }
    },
    getSignatoryType(element) {
      if (this.isDeskRhDomain && element.type === 'externalSignatory') {
        return this.$i18n.t('paraphers.create.addSignatories.signatoriesType.employee');
      }
      return this.$i18n.t(`paraphers.create.addSignatories.signatoriesType.${element.type}`);
    },
    minDate(index) {
      if (this.Signbook.allowFirstThenSimultaneousSteps ||
        (index === 0) ||
        (index > 0 &&
          (this.Signbook.signatories[index - 1].date === undefined || this.Signbook.signatories[index - 1].date === null)
        )) {
        return this.today;
      }
      return this.Signbook.signatories[index - 1].date;
    },
    maxDate(index) {
      if (index === this.Signbook.signatories.length - 1 ||
        this.Signbook.signatories[index + 1].date === undefined ||
        this.Signbook.signatories[index + 1].date === null) {
        return this.Signbook.endingDate;
      }
      return this.Signbook.signatories[index + 1].date;
    },
  },
  mounted() {
    this.selected = this.Signbook.signatories.map(({ id, date, signerOnlyValidate, comment, initials, ...keepAttrs }) => keepAttrs);
    /*    if (!this.routeName.startsWith('templates.use')) {
      this.Signbook.signatories.map(item => {
        item.date = this.Signbook.endingDate;
        return item;
      });
    } */
  },
};
</script>
<style >
.col {
  display: flex;
  align-items: center;
}
.col1 {
  min-height: 80px;
}
.container {
  max-width: 100%;
  overflow-x: auto;
}
.list {
  position: relative;
  width: 100%;
}
.table {
  width: 100%;
  display: grid;
  align-items: center;
  grid-gap: 16px;
  grid-template-columns: 24px 14px minmax(150px, 200px) minmax(70px, auto)  135px minmax(100px, auto) min-content;
}
</style>
